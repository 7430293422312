const actionTypes = {
  UPDATE_HASH_NAVIGATION_TARGET: "UPDATE_HASH_NAVIGATION_TARGET",
  SCHEDULE_HASH_NAVIGATION: "SCHEDULE_HASH_NAVIGATION",
  SCHEDULE_UPDATE_SEARCH_QUERY: "SCHEDULE_UPDATE_SEARCH_QUERY",
  UPDATE_SEARCH: "UPDATE_SEARCH",
  UPDATE_SEARCHBAR_TEXT: "UPDATE_SEARCHBAR_TEXT",
  SWITCH_TO_SEARCH: "SWITCH_TO_SEARCH",
  EXIT_SEARCH: "EXIT_SEARCH",
  SET_MOBILE_MENU_OPEN: "SET_MOBILE_MENU_OPEN",
  FETCH_USER_LOGIN_STATUS: "FETCH_USER_LOGIN_STATUS",
  LOGOUT_FROM_DEV_PORTAL: "LOGOUT_FROM_DEV_PORTAL",
  SET_ACCESS_TOKEN: "SET_ACCESS_TOKEN",
  SET_HAID_FOR_QUICKSTART: "SET_HAID_FOR_QUICKSTART",
  SET_SIM_AUTHORIZATION_CODE: "SET_SIM_AUTHORIZATION_CODE",
  SET_API_AUTHORIZATION_CODE: "SET_API_AUTHORIZATION_CODE",
  SET_CURRENT_STEP_NUMBER: "SET_CURRENT_STEP_NUMBER",
}

export default actionTypes
