import axios from "axios"
import PropTypes from "prop-types"
import React from "react"
import { Provider } from "react-redux"
import {
  applyMiddleware,
  compose,
  createStore as reduxCreateStore,
} from "redux"
import axiosMiddleware from "redux-axios-middleware"
import createSagaMiddleware from "redux-saga"
import rootReducer from "."
import apiDocsSaga from "./saga"

const client = axios.create({
  responseType: "json",
  withCredentials: true,
})

const enhancedCompose = function enhanceComposeWithDevToolsSupport(
  reactDevToolsEnabled
) {
  return reactDevToolsEnabled &&
    typeof window === "object" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose
}

// debugging disabled for production
const debuggingEnabled = false

const sagaMiddleWare = createSagaMiddleware()

const createStore = () => {
  const reduxStore = reduxCreateStore(
    rootReducer,
    enhancedCompose(debuggingEnabled)(
      applyMiddleware(axiosMiddleware(client), sagaMiddleWare)
    )
  )
  sagaMiddleWare.run(apiDocsSaga)
  return reduxStore
}

const ReduxWrapper = ({ element }) => (
  /*
  (copied from [1] as a reminder why creating store is delayed)
   - Instantiating store in `wrapRootElement` handler ensures:
   - there is fresh store for each SSR page
   - it will be called only once in browser, when React mounts
   [1] https://github.com/gatsbyjs/gatsby/blob/master/examples/using-redux/wrap-with-provider.js
   */
  <Provider store={createStore()}>{element}</Provider>
)
ReduxWrapper.propTypes = {
  element: PropTypes.node.isRequired,
}
export default ReduxWrapper
