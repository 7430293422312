const throwErrorForMissingEnvironmentVariable = (name) => {
  throw new Error(`Mandatory environment variable ${name} is not defined`)
}

/*
  Constants initialized by environment variables during build process to make the devportal and
  successful login redirection urls configurable.
  The reference is replaced by its actual value during gatsby's build process, see [1].

  In order to ensure that application is never started/deployed with an inconsistent configuration we throw an error
  right here which should be the only place where these variables are directly referenced.
  [1] https://www.gatsbyjs.org/docs/environment-variables/#client-side-javascript
 */
const devPortalBaseUrl =
  process.env.GATSBY_DEV_PORTAL_BASE_URL ||
  throwErrorForMissingEnvironmentVariable("GATSBY_DEV_PORTAL_BASE_URL")
const loginRedirectionTarget =
  process.env.GATSBY_LOGIN_REDIRECTION_TARGET ||
  throwErrorForMissingEnvironmentVariable("GATSBY_LOGIN_REDIRECTION_TARGET")
const simulatorUrl =
  process.env.GATSBY_SIMULATOR_URL ||
  throwErrorForMissingEnvironmentVariable("GATSBY_SIMULATOR_URL")
const authorizeRedirect =
  process.env.GATSBY_AUTHORIZE_REDIRECT ||
  throwErrorForMissingEnvironmentVariable("GATSBY_AUTHORIZE_REDIRECT")
const apiBaseUrl = "https://api.home-connect.com"

// node syntax because used during build (no transpilation beforehand)
module.exports = {
  devPortalBaseUrl,
  loginRedirectionTarget,
  simulatorUrl,
  authorizeRedirect,
  apiBaseUrl,
}
