import "./src/constants/codeHighlighting.scss"
import "./src/constants/icons.scss"
import "./src/constants/style.scss"
import { navigate } from "@gatsbyjs/reach-router"
import WindowUtil from "./src/utils/window"

export { default as wrapRootElement } from "./src/store/ReduxWrapper"

// Quickstart: jump to anchor after redirect URI with authorization code is called
export const onClientEntry = () => {
  window.onload = () => {
    if (WindowUtil.getLocationSearch().startsWith("?code=")) {
      navigate("#step-2-authorization-url-and-response")
    }
  }
}
