import actionType from "../../actions/actionTypes"
import initialState from "./initialState"

export default function appReducer(state, action) {
  switch (action.type) {
    case `${actionType.FETCH_USER_LOGIN_STATUS}_SUCCESS`: {
      const userMetaData = action.payload.data
      return {
        ...state,
        userMetaData,
      }
    }
    case actionType.SWITCH_TO_SEARCH: {
      return {
        ...state,
        searchMode: true,
      }
    }
    case actionType.UPDATE_SEARCH: {
      let { searchMode } = state
      if (!state.searchMode && action.query !== "") {
        searchMode = true
      }
      return {
        ...state,
        searchQuery: action.query,
        searchMode,
      }
    }
    case actionType.UPDATE_SEARCHBAR_TEXT: {
      return {
        ...state,
        searchBarText: action.text,
      }
    }
    case actionType.EXIT_SEARCH: {
      if (state.searchMode) {
        return {
          ...state,
          searchBarText: "",
          searchQuery: "",
          searchMode: false,
        }
      }
      return state
    }
    case actionType.SET_MOBILE_MENU_OPEN: {
      return {
        ...state,
        mobileMenuOpen: action.mobileMenuOpen,
      }
    }
    case actionType.SET_API_AUTHORIZATION_CODE: {
      return {
        ...state,
        apiAuthorizationCode: action.apiAuthorizationCode,
      }
    }
    case actionType.SET_SIM_AUTHORIZATION_CODE: {
      return {
        ...state,
        simAuthorizationCode: action.simAuthorizationCode,
      }
    }
    case actionType.SET_ACCESS_TOKEN: {
      return {
        ...state,
        accessToken: action.token,
      }
    }
    case actionType.SET_HAID_FOR_QUICKSTART: {
      return {
        ...state,
        haidForQuickstart: action.haid,
      }
    }
    case actionType.SET_CURRENT_STEP_NUMBER: {
      return {
        ...state,
        currentQuickstartStep: action.step,
      }
    }
    case actionType.UPDATE_HASH_NAVIGATION_TARGET: {
      return {
        ...state,
        hashNavigationTarget: action.hash,
      }
    }
    default:
      return state ?? initialState
  }
}
