const initialState = {
  hashNavigationTarget: "",
  searchQuery: "",
  searchMode: false,
  mobileMenuOpen: false,
  searchBarText: "",
  userMetaData: {
    isLoggedIn: false,
    tokens: {
      clientId: "YOUR_CLIENT_ID",
    },
  },
  accessToken: "",
  haidForQuickstart: "",
  simAuthorizationCode: "",
  apiAuthorizationCode: "",
  currentQuickstartStep: "1",
}

export default initialState
