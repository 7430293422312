/**
 * This class is meant to be an abstraction from the directly accessible window object
 * to handle the case when it is not available (during build).
 * All access to the window object should be through this class to make it easier
 * to search for and help new developers understand this issue.
 * In addition, this class provides reasonable fallback values to avoid cluttering
 * the code with error handling of unexpected 'window is undefined' scenarios.
 *
 * Docs: https://www.gatsbyjs.com/docs/debugging-html-builds/#how-to-check-if-window-is-defined
 */
import { breakpoints } from "../constants/lengths"
import Logger from "./logger"

class WindowUtil {
  static isWindowDefined() {
    return typeof window !== "undefined"
  }

  static isDesktopView() {
    if (WindowUtil.isWindowDefined()) {
      return window.matchMedia(`(min-width: ${breakpoints.desktop})`).matches
    }
    return true
  }

  static getLocationSearch() {
    if (WindowUtil.isWindowDefined()) {
      return window.location.search
    }
    return ""
  }

  static getInnerHeight() {
    if (WindowUtil.isWindowDefined()) {
      return window.innerHeight
    }
    // there is no reasonable default value
    // using 0 would lead to misleading calculations
    return undefined
  }

  static userIsAtTopOfPage() {
    if (WindowUtil.isWindowDefined()) {
      return window.scrollY === 0
    }
    return false
  }

  static saveToClipboard(copiedText) {
    if (!WindowUtil.isWindowDefined()) {
      return
    }
    if (window.navigator.clipboard) {
      window.navigator.clipboard.writeText(copiedText)
    } else {
      // needed for e.g. Microsoft Edge version 44.x
      WindowUtil.fallbackCopyTextToClipboard(copiedText)
    }
  }

  static fallbackCopyTextToClipboard(text) {
    const textArea = document.createElement("textarea")
    textArea.value = text

    // Avoid scrolling to bottom
    textArea.style.top = "0"
    textArea.style.left = "0"
    textArea.style.position = "fixed"

    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()

    try {
      document.execCommand("copy")
    } catch (err) {
      Logger.error(`Unable to copy link to clipboard ${err}`)
    }

    document.body.removeChild(textArea)
  }
}

export default WindowUtil
