// breakpoints are defined 'mobile-first',
// i.e. label changes when the width grows(!) past a threshold.
export const breakpoints = {
  mobile: "0px", // exists just as a dummy value
  oneLineFooter: "497px", // footer changes from 2 lines to single line
  desktop: "768px", // sidebar is permanently visible
}

export const dimensions = {
  sidebarWidth: {
    mobile: "100%",
    desktop: "290px",
  },
  sidebarHeightCollapsed: {
    mobile: "90px",
  },
  externalLinksHeight: {
    mobile: "80px",
    oneLineFooter: "80px",
    desktop: "80px",
  },
  footerHeight: {
    mobile: "70px",
    oneLineFooter: "46px",
    desktop: "46px",
  },
}
