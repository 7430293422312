import { devPortalBaseUrl } from "../configuration/configuration"
import actionTypes from "./actionTypes"

export const updateHashNavigationTarget = (hash) => ({
  type: actionTypes.UPDATE_HASH_NAVIGATION_TARGET,
  hash,
})

export const scheduleHashNavigation = (hash) => ({
  type: actionTypes.SCHEDULE_HASH_NAVIGATION,
  hash,
})

export const updateSearchBarText = (text) => ({
  type: actionTypes.UPDATE_SEARCHBAR_TEXT,
  text,
})

export const scheduleUpdateSearchQuery = (query, delay) => ({
  type: actionTypes.SCHEDULE_UPDATE_SEARCH_QUERY,
  query,
  delay,
})
export const updateSearchQuery = (query) => ({
  type: actionTypes.UPDATE_SEARCH,
  query,
})

export const switchToSearch = () => ({
  type: actionTypes.SWITCH_TO_SEARCH,
})

export const exitSearchMode = () => ({
  type: actionTypes.EXIT_SEARCH,
})

export const setMobileMenuOpen = (mobileMenuOpen) => ({
  type: actionTypes.SET_MOBILE_MENU_OPEN,
  mobileMenuOpen,
})

export const fetchUserLoginStatus = () => ({
  type: actionTypes.FETCH_USER_LOGIN_STATUS,
  payload: {
    request: {
      url: `${devPortalBaseUrl}/user/api-docs-meta-data`,
    },
  },
})

export const logoutFromDevPortal = () => ({
  type: actionTypes.LOGOUT_FROM_DEV_PORTAL,
  payload: {
    request: {
      url: `${devPortalBaseUrl}/user/logout`,
    },
  },
})

export const setSimAuthorizationCode = (simAuthorizationCode) => ({
  type: actionTypes.SET_SIM_AUTHORIZATION_CODE,
  simAuthorizationCode,
})

export const setApiAuthorizationCode = (apiAuthorizationCode) => ({
  type: actionTypes.SET_API_AUTHORIZATION_CODE,
  apiAuthorizationCode,
})

export const setAccessToken = (token) => ({
  type: actionTypes.SET_ACCESS_TOKEN,
  token,
})

export const setHaidForQuickstart = (haid) => ({
  type: actionTypes.SET_HAID_FOR_QUICKSTART,
  haid,
})

export const setCurrentStepNumber = (step) => ({
  type: actionTypes.SET_CURRENT_STEP_NUMBER,
  step,
})
