import { delay, put, takeLatest, takeLeading } from "redux-saga/effects"
import actionTypes from "../../actions/actionTypes"
import {
  fetchUserLoginStatus,
  updateHashNavigationTarget,
  updateSearchQuery,
} from "../../actions"

function* fetchUpdatedLoginStatus() {
  yield put(fetchUserLoginStatus())
}

function* updateHashWithDelay(action) {
  yield delay(200)
  yield put(updateHashNavigationTarget(action.hash))
}

function* updateSearchQueryWithDelay(action) {
  yield delay(action.delay)
  yield put(updateSearchQuery(action.query))
}

function* apiDocsSaga() {
  yield takeLatest(
    `${actionTypes.LOGOUT_FROM_DEV_PORTAL}_SUCCESS`,
    fetchUpdatedLoginStatus
  )
  yield takeLatest(
    `${actionTypes.LOGOUT_FROM_DEV_PORTAL}_FAIL`,
    fetchUpdatedLoginStatus
  )
  yield takeLeading(actionTypes.SCHEDULE_HASH_NAVIGATION, updateHashWithDelay)
  yield takeLatest(
    actionTypes.SCHEDULE_UPDATE_SEARCH_QUERY,
    updateSearchQueryWithDelay
  )
}

export default apiDocsSaga
